<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">政策解答</div>
      <div class="h2">聚焦产业   政策引导   创新驱动</div>
<!--      <div>-->
<!--        <el-input placeholder="请输入内容" v-model="keyword" style="width: 600px;border-radius: 15px" class="input-with-select">-->
<!--        </el-input>-->
<!--      </div>-->
    </div>
    <div class="zhuti"  style="background: #fff">
      <avue-search :option="option"   @change="loadlist(true)"
                   v-model="form">
        <template #title="{}">
          <el-col :span="6">
            <avue-input v-model="form1.title" placeholder="请输入" ></avue-input>
          </el-col>
        </template>
      </avue-search>
      <div style="text-align: right;padding: 0 20px 20px 0">
        <el-button type="primary" size="mini" @click="loadlist(true)">筛选</el-button>
        <el-button  size="mini" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="zhuti">
      <div class="news" v-for="(ar,index) in arr" @click="goxq(ar)" :key="index">
        <div class="title"><i class="yuan"></i>{{ar.title}}</div>
        <div class="flex di">
          <div class="flex-1">
            发布机构:{{ar.source}}
          </div>
          <div class="artime">发布时间:{{ar.createTime}}</div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>

  </div>

</template>

<script>
import { fiveyears } from '../../utils/index'
import {policyindex, policypage, policyunscrambleindex, policyunscramblepage} from "../../request/moudle/zcfw";
export default {
  name: "zchj",
  data(){
    return {
      form: {
        levelId:'',
        createTime:'',
      },
      form1:{
        title:this.$route.query.keyword
      },
      page:{
        current:1,
        size:10,
        total:0
      },
      option: {
        column: [ {
          label: '标题',
          prop: 'title',
        },{
          label: '政策级别',
          prop: 'levelId',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: false,
          dicData:[],
        },
          {
            label: '发布时间',
            prop: 'createTime',
            multiple: false,
            dicData: fiveyears()
          }]
      },
      keyword:'',
      pageNo:1,
      total:1,
      arr:[],
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    gotolist(item){
      this.$router.push({
        path:'/gonggaolist',
        query:{
          type:'policy-index'
        }
      })
    },
    goxq(item){
      this.$router.push({
        path:'/xmsbxq',
        query:{
          id:item.policyId,
          activeName:'2'
        }
      })
    },
    gotoxq(item){
      this.$router.push({
        path:'/gonggaoxq',
        query:{
          id:item.id
        }
      })
    },
    loadconfig(){
      this.$api.zcfw.policyunscrambleindex().then((res)=>{
        const levelId=this.findObject(this.option.column, 'levelId')
        levelId.dicData=res.data.levelList
        levelId.dicData.unshift({ title: '不限', id: '' })
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        ...this.form,
        ...this.form1
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.zcfw.policyunscramblepage(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    reset(){
      for (const val of this.option.column){
        if(val.multiple){
          this.form[val.prop]=['']
        }else {
          this.form[val.prop]=''
        }
      }
      this.form1.title=''
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
  }
}
</script>

<style lang="scss" scoped>
.zchjs{
  background: #F5F5F5;
}
.jiedu{
  font-weight: bolder;
  margin-top: 10px;
  span{
    margin: 0 10px;
  }
}
.artime{
  display: flex;
  align-items: flex-end;
}
.news{
  background: #fff;
  padding: 20px;
  color: #3B3E45;
  margin-bottom: 20px;
  cursor: pointer;
  .title{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .yuan{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #A5ACB9;
    margin-right: 15px;
  }
  .di{
    font-size: 14px;
    padding-left: 27px;
  }
}
.fenye{
  text-align: center;
  padding: 30px 0;
}
.header{
  height: 220px;
  background: url("../../assets/banner/zcjd.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0 auto;
  margin-top: 62px;
  .left{
    width: 535px;
    margin-right: 25px;
    color: #fff;
    .cont{
      height: 249px;
    }
    .title{
      background: linear-gradient(0deg,#0078F2,#00BAFF);
      font-size: 18px;
      font-weight: 500;
      padding: 13px 26px;
      span{
        float: right;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
  .right{
    color: #fff;
    .title{
      background: linear-gradient(0deg,#0078F2,#00BAFF);
      font-size: 18px;
      font-weight: 500;
      padding: 13px 26px;
      span{
        float: right;
        font-size: 14px;
        margin-top: 4px;
      }
    }
    .news1{
      background: #fff;
      color: #4C4C4C ;
      font-size: 14px;
      padding: 15px 16px;
      border-bottom: 1px dashed  #4C4C4C;
      cursor: pointer;
      .type{
        width: 12px;
        height: 12px;
        margin: 4px 10px 0 10px;
        border-radius: 50%;
        background: #A5ACB9;
      }
      .time{
        width: 140px;
      }
    }
    .news1:nth-last-child(1){
      border-bottom: none;
    }
  }
  .right{

  }
}
.zhuti{
  width: 1200px;
  margin:30px auto;
  .sxx{
    background: #fff;
    padding: 20px;
  }
}
.title1{
  width: 1200px;
  margin:30px auto;
  position: relative;
  font-size: 20px;
  font-weight:550;
  color:#000000 ;
  span{
    border-top: 1px solid #000000;
    margin: 0 15px;
    margin-top: 14px;
  }
}
</style>