import { render, staticRenderFns } from "./zcjd.vue?vue&type=template&id=027a3bcf&scoped=true&"
import script from "./zcjd.vue?vue&type=script&lang=js&"
export * from "./zcjd.vue?vue&type=script&lang=js&"
import style0 from "./zcjd.vue?vue&type=style&index=0&id=027a3bcf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "027a3bcf",
  null
  
)

export default component.exports